import React from "react"
import { Link } from "gatsby"
import logo from '../images/logo.svg'
import { Tween } from 'react-gsap';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import mobileMenu from '../images/mobile-menu.svg'

export default (props) => {
  const navigationContainer = React.createRef()
  try {
    window.addEventListener('scroll', () => {
      let y = 1 + (window.scrollY || window.pageYOffset) / 150
      y = y < 1 ? 1 : y // ensure y is always >= 1 (due to Safari's elastic scroll)
      if(navigationContainer.current !== null && y > 1){
        navigationContainer.current.className = `navigation-container scroll`
      } else if (navigationContainer.current !== null && y === 1){
        navigationContainer.current.className = `navigation-container`
      }
    });
  } catch(e) {

  }

  let width = 0;
    try {
        width = window.innerWidth;
    } catch(e){

    }

    const [openMobileNavigation, setOpenMobileNavigation] = React.useState(false);

    if (width < 1024) {
      return (
        <div className="navigation-container" ref={navigationContainer}>
          <div>
            <div className='mobile-navigation-container'>
              <Tween
                duration={0.3}
                from={{
                  yPercent: -400,
                }}
                to={{
                  yPercent: 0,
                }}
              >
                <Link title={props.title} to="/" className="logo"><img alt={props.title} src={logo} width="125" /></Link>
              </Tween>
              <span className="menu-title">Menu</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="19.607" height="26.354" viewBox="0 0 19.607 26.354" onClick={() => setOpenMobileNavigation(!openMobileNavigation)}>
                <g id="Group_629" data-name="Group 629" transform="translate(-332 -19.5)">
                  <rect id="Rectangle_1485" data-name="Rectangle 1485" width="21.552" height="2.487" rx="1.243" transform="translate(351.607 19.5) rotate(90)"/>
                  <rect id="Rectangle_1489" data-name="Rectangle 1489" width="14.092" height="2.487" rx="1.243" transform="translate(351.211 45.854) rotate(180)"/>
                  <rect id="Rectangle_1486" data-name="Rectangle 1486" width="14.177" height="2.487" rx="1.243" transform="translate(345.829 26.875) rotate(90)"/>
                  <rect id="Rectangle_1488" data-name="Rectangle 1488" width="14.177" height="2.487" rx="1.243" transform="translate(340.158 26.875) rotate(90)"/>
                  <rect id="Rectangle_1487" data-name="Rectangle 1487" width="17.552" height="2.487" rx="1.243" transform="translate(334.487 23.5) rotate(90)"/>
                </g>
              </svg>
            </div>
          </div>
          <div>
            <nav className="mobile-navigation" style={{display: openMobileNavigation ? 'block' : 'none'}}>
              <AnchorLink stripHash to="/#youtube-player">Vidéos</AnchorLink>
              <AnchorLink stripHash to="/#news-section">Nouvelles</AnchorLink>
              <AnchorLink stripHash to="/#bio-section">Bio</AnchorLink>
              <AnchorLink stripHash to="/#contact">Contact</AnchorLink>
            </nav>
          </div>
        </div>
      )
    } else {
      return (
        <div className='navigation-container' ref={navigationContainer}>
          <Tween
            duration={0.3}
            from={{
              yPercent: -400,
            }}
            to={{
              yPercent: 0,
            }}
          >
            <nav className="navigation">
              <AnchorLink stripHash to="/#youtube-player">Vidéos</AnchorLink>
              <AnchorLink stripHash to="/#news-section">Nouvelles</AnchorLink>
              <Link title={props.title} to="/"><img alt={props.title} src={logo} /></Link>
              <AnchorLink stripHash to="/#bio-section">Bio</AnchorLink>
              <AnchorLink stripHash to="/#contact">Contact</AnchorLink>
            </nav>
          </Tween>
        </div>
      )
    }
  
}