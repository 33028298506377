import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Navigation from "../components/navigation"
import 'prismjs/themes/prism-okaidia.css';
import logoWhite from '../images/logo-white.svg'
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import FacebookBackstage from '../images/facebook-backstage.svg'
import FacebookBackstageMobile from '../images/facebook-backstage-mobile.svg'
import Facebook from '../images/facebook.svg'
import Instagram from '../images/instagram.svg'
import Spotify from '../images/spotify.svg'
import Youtube from '../images/youtube.svg'
import Apple from '../images/apple.svg'

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <div className="site-wrapper">
      <header className="site-header">
        <Navigation title={data.site.siteMetadata.title} />
      </header>
      {children}
      <footer className="site-footer">
        <nav className="nav">
          <AnchorLink stripHash to="/#youtube-player" title="Vidéos">Vidéos</AnchorLink>
          <AnchorLink stripHash to="/#news-section" title="Nouvelles">Nouvelles</AnchorLink>
          <Link title={data.site.siteMetadata.title} to="/"><img alt={data.site.siteMetadata.title} src={logoWhite} /></Link>
          <AnchorLink stripHash to="/#bio-section" title="Bio">Bio</AnchorLink>
          <AnchorLink stripHash to="/#contact" title="Contact">Contact</AnchorLink>
        </nav>
      </footer>
      <div className="social-medias-bar">
        <a href="https://www.facebook.com/PrimaPapaRocker" title="Facebook" target="_blank"><img src={Facebook} alt="Facebook" target="_blank" /></a>
        <a href="https://www.facebook.com/groups/paparocker" title="Facebook" className="facebook-desktop" target="_blank"><img src={FacebookBackstage} alt="Facebook" /></a>
        <a href="https://www.facebook.com/groups/paparocker" title="Facebook" className="facebook-mobile" target="_blank"><img src={FacebookBackstageMobile} alt="Facebook" /></a>
        <a href="https://www.instagram.com/primapaparocker/?hl=fr-ca" title="Instagram" target="_blank"><img src={Instagram} alt="Instagram" /></a>
        <a href="https://open.spotify.com/artist/5qK9vcHiC1JgnFizxPLelt" title="Spotify" target="_blank"><img src={Spotify} alt="Spotify" /></a>
        <a href="https://www.youtube.com/playlist?reload=9&list=PLCQTwA8T16T4PDsjevAClPIwZYzByDRfd" title="Youtube" target="_blank"><img src={Youtube} alt="Youtube" /></a>
        <a href="https://music.apple.com/fr/artist/papa-rocker/id1466284109" title="Apple" target="_blank"><img src={Apple} alt="Apple" /></a>
    </div>
    </div>
  )
}
